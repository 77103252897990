<template>
  <div class="vlw__header">
    <PrimaryHeader :style="appEnvStyle">
      <!-- Left -->
      <template v-slot:left>
        <div v-if="appVersion" v-html="appVersion" />
      </template>

      <!-- Right -->
      <template v-slot:right>
        <label class="mr-8">
          <span class="mr-2">Dark Mode</span>
          <input type="checkbox" v-model="isDarkMode" />
        </label>

        <VlLink :href="linkToCRM" target="_blank">CRM</VlLink>

        <VlLink :href="linkToGenesys" style="margin-left: 6px" target="_blank"
          >Genesys</VlLink
        >

        <div class="flex items-center ml-8">
          <div class="vlw__header__user-image"></div>
          <div v-if="userName" class="vlw__header__user-name">
            {{ userName }}
          </div>
        </div>

        <StatusTimer class="ml-4" />

        <div v-if="user.isLoggedIn" style="margin-left: 15px">
          <div
            class="selected-status"
            :class="{ open: isDropdownOpen }"
            @click="toggleDropdown"
          >
            {{ selectedStatus + ' - ' + user.status || 'Select a Status' }}
            <span v-if="isDropdownOpen">▲</span>
            <span v-else>▼</span>
          </div>

          <!-- Dropdown (conditionally visible) -->
          <div v-if="isDropdownOpen" class="custom-dropdown">
            <div
              v-for="(item, index) in statusMessages"
              :key="index"
              class="dropdown-category"
            >
              <!-- Category Header -->
              <div
                v-if="item.isNameVisible"
                class="category-header"
                @click="toggleCategory(index)"
              >
                {{ item.category }}
                <span v-if="isCategoryOpen(index)">▼</span>
                <span v-else>►</span>
              </div>

              <!-- Substatuses (conditionally shown) -->
              <ul v-if="isCategoryOpen(index)" class="substatus-list">
                <li
                  v-for="(status, subIndex) in item.statuses"
                  :key="subIndex"
                  @click="selectStatus(status.status)"
                  :class="{ selected: selectedStatus === status.status }"
                >
                  {{ status.status }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <CtiLogin v-else />

        <VlButton
          class="ml-8"
          @click.prevent="handleLogOut"
          title="Volledig uitloggen"
          :mod-narrow="true"
        >
          <VlIcon icon="logout" />
        </VlButton>
      </template>
    </PrimaryHeader>

    <CTIToolBar />
  </div>
</template>

<script>
import { identity } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import CTIToolBar from '@/components/header/CTIToolbar';
import PrimaryHeader from '@/components/header/PrimaryHeader';
import bus from '@/utils/bus';
import CtiLogin from './CtiLogin';
import StatusTimer from '@/components/StatusTimer';

const {
  VUE_APP_CRM_URL,
  VUE_APP_ENV,
  VUE_APP_ENV_COLOR,
  VUE_APP_VERSION,
  VUE_APP_CTI_MPC_URL,
} = process.env;

const LOG_OUT = 'Log out';

export default {
  components: {
    CTIToolBar,
    CtiLogin,
    PrimaryHeader,
    StatusTimer,
  },

  data() {
    return {
      isDropdownOpen: false, // Controls dropdown visibility
      selectedStatus: null,
      openCategories: [], // To track which categories are open
    };
  },

  created() {
    // Initialize open categories based on the `isCollapsed` property
    this.openCategories = this.statusMessages
      .map((category, index) => (!category.isCollapsed ? index : null))
      .filter((index) => index !== null); // Only include indices of open categories
  },

  computed: {
    ...mapGetters('cti', ['isConnected', 'isAlerting']),
    ...mapGetters('authentication', ['isLoggedIn', 'userName']),
    ...mapState('cti', ['statusMessages', 'user']),
    ...mapGetters('metadata', ['getLabelByName']),

    isDarkMode: {
      get() {
        return this.$store.state.ui.isDarkMode;
      },

      set(value) {
        this.$store.commit('ui/UPDATE_DARK_MODE', value);
      },
    },

    appVersion() {
      return `v${[VUE_APP_VERSION, VUE_APP_ENV]
        .filter(identity)
        .join(' &mdash; ')}`;
    },

    appEnvStyle() {
      return VUE_APP_ENV_COLOR ? `background: ${VUE_APP_ENV_COLOR};` : null;
    },

    linkToCRM() {
      return VUE_APP_CRM_URL;
    },

    linkToGenesys() {
      return VUE_APP_CTI_MPC_URL;
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    selectStatus(status) {
      this.selectedStatus = status;
      this.isDropdownOpen = false; // Close the dropdown after selection
      this.onChangeStatus(status);
    },

    onChangeStatus(id) {
      if (id === LOG_OUT) {
        this.ctiLogOut();
        return;
      }
      // Dispatch action to the CTI store
      // this.updateStatus(id);
      //TODO CHECK NAMESPACE ISSUE

      this.$store
        .dispatch('cti/updateStatus', id)
        .then((response) => console.log('Update status success:', response))
        .catch((error) => console.error('Update status failed:', error));

      // Close the popover
      if (this.$refs.statusPopover) {
        this.$refs.statusPopover.expanded = false;
      }
    },

    toggleCategory(index) {
      if (this.openCategories.includes(index)) {
        this.openCategories = this.openCategories.filter((i) => i !== index);
      } else {
        this.openCategories.push(index);
      }
    },
    isCategoryOpen(index) {
      return this.openCategories.includes(index);
    },
  },

  ...mapActions('cti', {
    updateStatus: 'updateStatus',
    ctiLogOut: 'logOut',
  }),
  ...mapActions('authentication', {
    crmLogOut: 'logOut',
  }),

  // Triggered when user confirms logout
  confirmLogOut() {
    Promise.all([this.ctiLogOut(), this.crmLogOut()]);
  },

  // Triggered when clicking the logout button
  handleLogOut() {
    // Prevent logout if currently connected or alerting
    if (this.isConnected || this.isAlerting) {
      // Exit early
      const label = this.getLabelByName('connected_logout');

      return bus.$emit('show-modal', {
        message: label.title,
        body: label.body,
        confirmText: label.confirm,
        confirm: true,
      });
    }

    // Ask user to confirm logout
    const label = this.getLabelByName('confirm_logout');

    bus.$emit('show-modal', {
      message: label.title,
      body: label.body,
      confirmText: label.confirm,
      confirm: this.confirmLogOut,
      cancel: true,
      cancelText: label.cancel,
    });
  },
};
</script>
<!-- hover toevoegen
height/scrollbar fixen 
gereserveerde status toevoegen: iemand is in call en selecteerd dan 'in pauze', dan moet dit zo verschijnen dat de pauze is aangevraagd en zal doorgestuurd worden
naar CTI vanzodra de call/mail/chat gedaan is

-->
<style scoped>
.custom-dropdown {
  margin-top: 560px; /* Push the dropdown down by 100px */
  width: 400px;
  max-width: 500px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  height: 608px; /* Set a fixed height for the dropdown */
  overflow-y: auto; /* Enable scrolling for long content */
}

.dropdown-category {
  margin-bottom: 10px;
}

.category-header {
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.substatus-list {
  list-style-type: none;
  padding-left: 20px;
}

.substatus-list li {
  cursor: pointer;
  padding: 3px 0;
}

.substatus-list li.selected {
  font-weight: bold;
  color: #05c;
}

.selected-status {
  font-weight: bold;
  color: #05c;
  width: 400px;
  margin-left: 30px;
}

.substatus-list li:hover {
  background-color: #05c; /* Light gray background on hover */
  color: #e0e0e0; /* Change text color on hover */
}
</style>

<style lang="scss">
.vlw__header__user-image {
  background: #d8d8d8;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
}

.vlw__header__user-name {
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
}
</style>

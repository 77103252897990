import { authenticationContext } from '@/utils/CrmAuthentication';
import { UPDATE_IS_REDIRECTING, UPDATE_USER } from '@/constants/mutation-types';

export default {
  initialize({ commit, dispatch }) {
    if (
      authenticationContext.isCallback(window.location.hash) ||
      window.self !== window.top
    ) {
      commit(UPDATE_IS_REDIRECTING, true);

      authenticationContext.handleWindowCallback();

      return;
    }

    const user = authenticationContext.getCachedUser();
    if (user) {
      /*
      authenticationContext.config.extraQueryParameter =
        'login_hint=' + user.profile.unique_name; */

      commit(UPDATE_IS_REDIRECTING, false);

      commit(UPDATE_USER, user);

      dispatch('cti/logIn', { isAuto: true }, { root: true });
    } else {
      dispatch('login');
    }
  },

  login({ commit }) {
    commit(UPDATE_IS_REDIRECTING, true);

    authenticationContext.login();
  },

  logOut() {
    authenticationContext.logOut();
  },
};

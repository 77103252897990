import { isValid, parseISO } from 'date-fns';

import bus from '@/utils/bus';
import { CTI_EVENT, USER_EVENT } from '@/constants';
import {
  ADD_INTERACTION,
  UPDATE_INTERACTIONS,
  UPDATE_LOADING_STATE,
  UPDATE_USER,
} from '@/constants/mutation-types';
import { CONNECTED, UNASSIGNED } from '@/constants/interaction-states';
import { alertUser } from '@/utils';
import { ACW, I2C_ACW } from '@/constants';

import handleIncomingInteraction from './handle-incoming-interaction';

export default (context) =>
  ({ type, data }) => {
    if (!type || !data) {
      // cti sends messages that need to be ignored
      return;
    }

    const { commit, getters, dispatch, rootGetters } = context;

    const { interactions, interactionTransferIsInternal } = data;

    commit(UPDATE_INTERACTIONS, interactions);

    console.log(data);

    switch (type) {
      case USER_EVENT:
        {
          const {
            user,
            statusId,
            loggedIn,
            statusChangedTimestamp,
            statusDurationInSeconds,
            customStatus,
            reservedCustomStatus,
          } = data;

          let dateStatusChanged = new Date(statusChangedTimestamp);

          if (!isValid(dateStatusChanged)) {
            dateStatusChanged = parseISO(statusChangedTimestamp);
          }

          if (!isValid(dateStatusChanged)) {
            const getLabelByName = rootGetters['metadata/getLabelByName'];

            const label = getLabelByName('cti_invalid_date');

            bus.$emit('show-modal', {
              message: label.title,
              body: label.body.replace(
                '${statusChangedTimestamp}',
                statusChangedTimestamp,
              ),
            });

            dateStatusChanged = new Date();
          }

          commit(UPDATE_USER, {
            name: user,
            status: statusId,
            isLoggedIn: !!loggedIn,
            dateStatusChanged,
            statusDurationInSeconds,
            customStatus: customStatus,
            reservedCustomStatus: reservedCustomStatus,
          });

          if ([ACW, I2C_ACW].includes(statusId)) {
            dispatch(
              `sidebar/${ADD_INTERACTION}`,
              { type: ACW },
              { root: true },
            );
          }
        }
        break;
    }

    const { transferInteraction: parentInteraction = {} } = getters;

    const { interactionState, interactionWorkgroup } = parentInteraction;

    switch (interactionState) {
      case CONNECTED: {
        if (!rootGetters['contact/contactId']) {
          if (type === CTI_EVENT) {
            alertUser(interactionWorkgroup);
          }

          handleIncomingInteraction(context)(parentInteraction);
        }
        break;
      }

      case UNASSIGNED: {
        if (interactionTransferIsInternal) {
          dispatch('contact/getContact', null, { root: true }); // reset contact
        }
        commit('ui/internalTransfer/HIDE', null, { root: true });
        break;
      }
    }

    commit(UPDATE_LOADING_STATE, false);
  };

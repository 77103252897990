import {
  UPDATE_INTERACTIONS,
  UPDATE_LOADING_STATE,
  UPDATE_USER,
} from '@/constants/mutation-types';

import { LOGIN } from '@/constants/action-types';

import { makeAsyncMutationTypes } from '@/utils';

const [login] = [LOGIN].map(makeAsyncMutationTypes);

export default {
  [login.REQUEST](state) {
    state.isLoading = true;
  },

  [login.SUCCESS](state, data) {
    state.isLoading = false;

    const statusMessagesInfo = data?.statusMessagesInfo || [];
    // Map and flatten the statuses into the required structure

    state.statusMessages = statusMessagesInfo.map((category) => ({
      category: category.category,
      isCollapsed: category.isCollapsed,
      isNameVisible: category.isNameVisible,
      statuses: category.statuses.map((statusObj) => ({
        status: statusObj.status,
      })),
    }));
  },

  [login.FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },

  [UPDATE_INTERACTIONS](state, interactions) {
    state.interactions = interactions;
  },

  [UPDATE_LOADING_STATE](state, isLoading) {
    state.isLoading = isLoading;
  },

  [UPDATE_USER](state, partialUser) {
    state.user = { ...state.user, ...partialUser };
  },
};
